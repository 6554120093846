import React from 'react';
import './App.css';
import Dashboard from "./components/Dashboard";
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
import {Container} from 'react-bootstrap';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Diagrams from './components/common/Diagrams';
import MatchesToday from './components/MatchesToday';

function App() {
  return (
    <Router>
      <Header/>
      <Container>
        <Routes>
          <Route path="/:providerName/:eventId/diagrams" element={<Diagrams />} />
          <Route path="/" element={<Dashboard />} />
          <Route path="/matches-today" element={<MatchesToday />} />
        </Routes>
      </Container>
      <Footer />
    </Router>
  );
}

export default App;
