import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { MATCH_API } from 'src/config/config';
import { FilteredMatchFields } from 'src/types';

const MatchesToday = () => {
  const [matches, setMatches] = useState<FilteredMatchFields[]>([]);

  useEffect(() => {
    const fetchMatchesToday = async () => {
      try {
        const response = await axios.get(`${MATCH_API}/matches/today`);
        return response.data as FilteredMatchFields[];
      } catch (error) {
        console.error('Error fetching data:', error);
        return [];
      }
    };

    fetchMatchesToday().then(data => {
      const groupedMatches: { [eventName: string]: FilteredMatchFields[] } = {};
      data.forEach(match => {
        if (!groupedMatches[match.eventName]) {
          groupedMatches[match.eventName] = [];
        }
        groupedMatches[match.eventName].push(match);
      });

      const consolidatedMatches: FilteredMatchFields[] = [];
      for (const eventName in groupedMatches) {
        if (groupedMatches[eventName].length > 0) {
          const consolidatedMatch = { ...groupedMatches[eventName][0] };
          consolidatedMatch.streamProviderName = groupedMatches[eventName].map(match => match.streamProviderName).join(', ');
          consolidatedMatch.eventUrl = groupedMatches[eventName].map(match => match.eventUrl).join(', ');
          consolidatedMatches.push(consolidatedMatch);
        }
      }

      const sortedMatches = consolidatedMatches.sort(
        (a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
      );

      setMatches(sortedMatches);
    });
  }, []);

  return (
    <Container>
      <Row>
        <Col>
          <h2>Today's Matches</h2><br />
        </Col>
      </Row>
      <Row>
        <Col>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Event Name</th>
                <th>Start Date</th>
                <th>Stream Providers</th>
              </tr>
            </thead>
            <tbody>
              {matches.map(match => (
                <tr key={match.eventId}>
                  <td>{match.eventName}</td>
                  <td>{new Date(match.startDate).toLocaleString()}</td>
                  <td>
                    {match.eventUrl.split(', ').map((url, index) => (
                      <span key={index}>
                        <a href={url} target="_blank" rel="noopener noreferrer">
                          {match.streamProviderName.split(', ')[index]}
                        </a>
                        {index < match.eventUrl.split(', ').length - 1 && ', '}
                      </span>
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default MatchesToday;
