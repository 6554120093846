import store from 'src/redux/store';
import { SOCKET_PROTOCOL, getSocketUrl } from './socketConfig';
import { SubscribeAction, Subscription, WebSocketStatus } from 'src/types';
import { initializeChart, requestSubscription, subscribeChart, unSubscribeChart, updateChartById } from 'src/redux/actions/chartActions';
import { generateSubscriptionID } from 'src/utils/generateSubscriptionID';

export class WebSocketService {
  private socket: WebSocket | null = null;
  private providerName: string;
  private eventId: string;
  private userAgent: string;
  private status: WebSocketStatus = 'ready';
  private statusChangeCallback: ((status: WebSocketStatus, message: any) => void) | null = null;
  private reconnectInterval: number = 3000;
  private reconnectTimeout: NodeJS.Timeout | null = null;

  constructor(providerName: string, eventId: string, statusChangeCallback: (status: WebSocketStatus, message: any) => void) {
    this.providerName = providerName;
    this.eventId = eventId;
    this.userAgent = navigator.userAgent;
    store.dispatch(initializeChart());
    this.statusChangeCallback = statusChangeCallback;
    this.connect();
  }

  private connect(reconnect?: boolean) {
    this.socket = this.createWebsocketBasedOnBrowserType();
    this.socket.onopen = () => {
      console.log('WebSocket connection opened');
      this.setStatus('opened');

      if (reconnect) {
        store.getState().chart.forEach((chart) => {
          const subscription: Subscription = {
            action: SubscribeAction.Subscribe,
            diagramConfig: chart.diagramConfig,
          };

          if (!this.socket) {
            console.log('Failed to resubscribe to charts');
            return
          }

          this.socket.send(JSON.stringify(subscription));
        });
      }
    };
    this.socket.onclose = () => {
      console.log('WebSocket connection closed');
      this.setStatus('closed');
      this.reconnect();
    };
    this.socket.onerror = (error) => {
      console.error('WebSocket connection error:', error);
      this.setStatus('error');
      this.reconnect();
    };

    this.socket.onmessage = (event) => {
      const { action, subscriptionID, dataPoints } = JSON.parse(event.data);

      if (action === SubscribeAction.Subscribed && subscriptionID) {
        store.dispatch(subscribeChart(subscriptionID));
      } else if (subscriptionID && dataPoints) {
        store.dispatch(updateChartById(subscriptionID, dataPoints));
      } else if (action === SubscribeAction.UnSubscribed && subscriptionID) {
        store.dispatch(unSubscribeChart(subscriptionID));
      }

      if (this.statusChangeCallback) {
        this.statusChangeCallback(this.status, event.data);
      }
    };
  }

  public sendSubscribe(subscribeMessage: Subscription, displayName: string) {
    store.dispatch(
      requestSubscription(
        generateSubscriptionID(subscribeMessage.diagramConfig),
        subscribeMessage.diagramConfig,
        displayName
      )
    );

    if (this.socket) {
      this.socket.send(JSON.stringify(subscribeMessage));
    }
  }

  public sendUnSubscribe(subscribeMessage: Subscription) {
    if (this.socket) {
      this.socket.send(JSON.stringify(subscribeMessage));
    }
  }

  public close() {
    if (this.socket) {
      this.socket.close();
      console.log("Websocket closed.")
      store.dispatch(initializeChart());
    }
  }

  private createWebsocketBasedOnBrowserType(): WebSocket {
    if (this.userAgent.indexOf('Firefox') !== -1) {
      // Firefox
      return new WebSocket(getSocketUrl(this.providerName, this.eventId), SOCKET_PROTOCOL);
    } else if (this.userAgent.indexOf('Chrome') !== -1) {
      // Chrome
      return new WebSocket(getSocketUrl(this.providerName, this.eventId));
    } if (this.userAgent.indexOf('MSIE') !== -1) {
      // Internet Explorer, needs to be checked
      return new WebSocket(getSocketUrl(this.providerName, this.eventId));
    } else if (this.userAgent.indexOf('Safari') !== -1) {
      // Safari
      return new WebSocket(getSocketUrl(this.providerName, this.eventId));
    } else if (this.userAgent.indexOf('Opera') !== -1) {
      // Opera
      return new WebSocket(getSocketUrl(this.providerName, this.eventId));
    } else {
      // Unknown browser
      console.log('Browser: Unknown');
      return new WebSocket(getSocketUrl(this.providerName, this.eventId), SOCKET_PROTOCOL);
    }
  }

  public reconnect() {

    this.reconnectTimeout = setTimeout(() => {

      if (this.reconnectTimeout) {
        clearTimeout(this.reconnectTimeout);
      }

      this.connect(true);

    }, this.reconnectInterval);
  }

  public setStatus(newStatus: WebSocketStatus) {
    this.status = newStatus;
    if (this.statusChangeCallback) {
      this.statusChangeCallback(newStatus, null);
    }
  }

  public getStatus = () => this.status;
}
