import { combineReducers } from 'redux';
import matchReducer from './matchReducer';
import chartReducer from './chartReducer';

const rootReducer = combineReducers({
  match: matchReducer,
  chart: chartReducer,
});

export default rootReducer;
