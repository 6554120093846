import { ChartDataPoint } from "./../types";

export function generateChartInitValues(): ChartDataPoint[] {
    const chartData: ChartDataPoint[] = [];
  
    for (let i = 0; i <= 90; i++) {
      const dataPoint: ChartDataPoint = {
        timestamp: i,
        label: i + "'",
        value: i === 0 ? 0 : null,
        periodID: null,
      };
  
      chartData.push(dataPoint);
    }
  
    return chartData;
}
