import { ChartDataPoint, ChartSubscription, ChartType } from "src/types";
import { isMetricGoals } from "../../../../utils/isMetricGoals";

export const getInterval = (index: any, chart: ChartSubscription) => {
    return index === 0 || index === chart.dataPoints.length - 1 || index % 5 === 0;
}

export const getMarkLineForPeriod = (label: string, timeStamp: number, firstOffset: number) =>
  ({
    xAxis: timeStamp,
    lineStyle: {
      type: 'dashed',
      color: 'grey',
    },
    label: {
      show: true,
      formatter: label,
      color: 'grey',
      offset: [firstOffset, 0],
    },
  });

export const getMarkLines = (chart: ChartSubscription) => {
  return chart.dataPoints
    .filter((dataPoint: ChartDataPoint) => dataPoint.value !== null && dataPoint.value > 0)
    .map((dataPoint: ChartDataPoint) => {
      return {
        xAxis: dataPoint.timestamp,
        symbol: `image://${require('../../../../assets/images/ball.png')}`,
        symbolSize: [20, 20],
        label: {
          show: true,
          formatter: dataPoint.label,
          color: 'darkgreen',
          offset: [0, 10],
        },
      };
  });
};

export const getSymbolData = (chart: ChartSubscription) => {
  const symbolData = [];

  for (let i = 0; i < chart.dataPoints.length; i++) {
    const dataPoint = chart.dataPoints[i];
    if (dataPoint.value !== null && dataPoint.value > 0) {
      symbolData.push({
        value: dataPoint.value,
        timestamp: i,
      });
    }
  }

  return symbolData;
};

export const chartConfig = (chart: ChartSubscription) => ({
  tooltip: {
    show: false,
  },
  visualMap: {
    show: false,
    type: 'continuous',
    min: -0.001,
    max: 0.001,
    inRange: {
      color: ['#f3a4bf', '#3062a6'],
    },
    position: 'top',
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: chart.dataPoints.map((c) => c.label),
    axisLabel: {
      interval: (index: any) => getInterval(index, chart),
    },
  },
  yAxis: {
    show: !isMetricGoals(chart),
    type: 'value',
    position: 'top',
  },
  series: [
    {
      name: 'Data',
      type: 'line',
      smooth: true,
      connectNulls: true,
      showSymbol: chart.diagramConfig.chart_type === ChartType.Sum,
      waveAnimation: false,
      renderLabelForZeroData: false,
      selectedMode: false,
      animation: false,
      data: isMetricGoals(chart) ? getSymbolData(chart) : chart.dataPoints.map(c => c.value),
      markLine: isMetricGoals(chart) ? {
        symbol: [],
        data: getMarkLines(chart),
        silent: true,
        itemStyle: {
          color: 'rgba(0, 0, 0, 0)'
        },
      } : null,
      areaStyle: chart.diagramConfig.chart_type === ChartType.Sum ? undefined : {},
      dataZoom: [
        {
          type: 'slider',
          start: 0,
          end: 100,
        },
        {
          type: 'inside',
          start: 0,
          end: 100,
        },
        {
          type: 'slider',
          yAxisIndex: 0,
          filterMode: 'empty',
        },
      ],
    },
  ],
});
