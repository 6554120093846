import { SOCKET } from "src/config/config"
import { PROD } from "src/config/config"

export const SOCKET_PROTOCOL = 'SOAP'

const WS_DIAGRAMS = 'ws/diagrams'

export const getSocketUrl = (providerName: string, eventId: string): string => {

    if (PROD) {
        return `${SOCKET}/${providerName}/${eventId}/${WS_DIAGRAMS}`
    }

    return `${SOCKET}/${WS_DIAGRAMS}`
}
