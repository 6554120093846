import {Metric} from "src/types";

export const MOCK_SCOPES = ['HomeTeam', 'AwayTeam']
export const METRIC_PASS_TOTAL = "PassTotal"
export const METRIC_GOALS = "Goals"

export const Metrics: Metric[] = [
    { value: METRIC_PASS_TOTAL, label: "Pass Total"},
    { value: "FieldTilt", label: "Field Tilt" },
    { value: "KeyPasses", label: "Key Passes" },
    { value: "PassCompletion", label: "Pass completion" },
    { value: "PassingDirectness", label: "Passing Directness" },
    { value: "SuccessfulPassesInFinalThird", label: "Successful passes in final third" },
    { value: "UnSuccessfulPassesInFinalThird", label: "Unsuccessful passes in final third" },
    { value: "PassesInFinalThirdTotal", label: "Passes in final third total" },
    { value: "PassesInFinalThirdCompletion", label: "Passes in final third completion" },
    { value: "ProgressivePassTotalSuccessful", label: "Progressive passes total successful" },
    { value: "ProgressivePassTotalUnSuccessful", label: "Progressive passes total unsuccessful" },
    { value: "ProgressivePassesTotal", label: "Progressive passes total" },
    { value: "ProgressivePassesCompletion", label: "Progressive passes completion" },
    { value: "PassesInOpponentBoxTotal", label: "Passes in opponent box" },
    { value: "SuccessfulPassesInOpponentBox", label: "Successful passes in opponent box" },
    { value: "UnSuccessfulPassesInOpponentBox", label: "Unsuccessful passes in opponent box" },
    { value: "PassesInOpponentBoxCompletion", label: "Passes in opponent box completion" },
    { value: "TotalPassLength", label: "Total pass length" },
    { value: "XGTotal", label: "xG total" },
    { value: "XTTotal", label: "xT total" },
    { value: "XTTotalMixed", label: "xT total mixed" },
    { value: "XTTotalAlt", label: "xT total alt" },
    { value: "XTTotalAltMixed", label: "xT total alt mixed" },
    { value: "PPDA", label: "PPDA" },
    { value: METRIC_GOALS, label: "Goals" },
    { value: "Fouls", label: "Fouls" },
    { value: "BigChance", label: "Big Chance" },
    { value: "ChanceMissed", label: "Chance Missed (Big Chance missed)" },
    { value: "Shots", label: "Shots" },
    { value: "ShotsFromBox", label: "Shots from box" },
    { value: "ShotsOnGoal", label: "Shots on goal" },
    { value: "ShotsOnGoalFromBox", label: "Shots on goal from box" } 
]
