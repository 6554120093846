import { ChartSubscription } from 'src/types';
import { ChartAction, INITIALIZE_CHART, REQUEST_SUBSCRIPTION, SUBSCRIBE_CHART, UNSUBSCRIBE_CHART, UPDATE_CHART_BY_ID } from '../actions/chartActions';
import { generateChartInitValues } from 'src/utils/generateChartInitValues';

const initialState: ChartSubscription[] = []

const chartReducer = (state = initialState, action: ChartAction): ChartSubscription[] => {
  switch (action.type) {
    case REQUEST_SUBSCRIPTION: {
      const { subscriptionID, diagramConfig, displayName } = action.payload;
      if (state.some(s => s.subscriptionID === subscriptionID)) {
        return state;
      }
      return [
        ...state, 
        {
          subscriptionID: subscriptionID,
          subscribed: false,
          diagramConfig: diagramConfig,
          displayName,
          dataPoints: generateChartInitValues(),
        }
      ];
    }
    case SUBSCRIBE_CHART:
      return state.map(subscription => {
        if (subscription.subscriptionID === action.payload.subscriptionID) {
          return {
            ...subscription,
            subscribed: true,
          };
        } else {
          return subscription;
        }
      });
    case UNSUBSCRIBE_CHART:
      return state.filter(subscription => subscription.subscriptionID !== action.payload.subscriptionID);
    case UPDATE_CHART_BY_ID:
      return state.map(subscription => {
        if (subscription.subscriptionID === action.payload.subscriptionID) {
          const existingDataPoints = subscription.dataPoints;
          const newDataPoints = action.payload.dataPoints ?? [];
          const mergedDataPoints = existingDataPoints.map(existingDataPoint => {
            const newDataPoint = newDataPoints.find(newDataPoint => newDataPoint.timestamp === existingDataPoint.timestamp);
            if (newDataPoint) {
              return {
                ...existingDataPoint,
                value: newDataPoint.value,
                label: newDataPoint.label,
                periodID: newDataPoint.periodID
              };
            } else {
              return existingDataPoint;
            }
          });
          const newDataPointsToAdd = newDataPoints.filter(newDataPoint => !existingDataPoints.some(existingDataPoint => existingDataPoint.timestamp === newDataPoint.timestamp));
          const updatedDataPoints = [...mergedDataPoints, ...newDataPointsToAdd];
          updatedDataPoints.sort((a, b) => a.timestamp - b.timestamp);
          return {
            ...subscription,
            dataPoints: updatedDataPoints,
          };
        } else {
          return subscription;
        }
    });
    case INITIALIZE_CHART: {
      return initialState;
    }
    default:
      return state;
  }
};

export default chartReducer;
