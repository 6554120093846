import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'src/redux/types';
import { DiagramConfig, SubscribeAction, Subscription } from 'src/types';
import { WebSocketService } from '../../../services/websocket/WebSocketService';
import { Alert, Col, Row } from 'react-bootstrap';
import ConfigForm from './ConfigForm';
import Charts from './Charts';
import { useParams } from 'react-router-dom';
import { fetchMatches } from 'src/redux/actions/matchActions';
import { WebSocketStatus } from '../../../types/index';

const Diagrams = () => {
  const dispatch = useDispatch<any>();
  const state = useSelector((state: State) => state);
  const [socketService, setSocketService] = useState<WebSocketService>();
  const [socketStatus, setSocketStatus] = useState<WebSocketStatus>();
  const { providerName, eventId } = useParams();

  const handleUnSubscribe = (diagramConfig: DiagramConfig) => {
    const unSubscription: Subscription = {
      action: SubscribeAction.UnSubscribe,
      diagramConfig,
    };
    sendUnSubscribe(unSubscription);
  };

  useEffect(() => {
    if (providerName && eventId) {
      const ws = new WebSocketService(
        providerName,
        eventId,
        (socketStatus) => {
          setSocketStatus(socketStatus);
        }
      );
      setSocketService(ws);
    }
    // needed to not start more websocket connections:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(fetchMatches());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      socketService?.close();
    };
  }, [socketService]);

  const event = state.match.matches.find((item) => item.eventId === eventId);

  const sendSubscribe = (subscribeMessage: Subscription, displayName: string) =>
    socketService?.sendSubscribe(subscribeMessage, displayName);

  const sendUnSubscribe = (subscribeMessage: Subscription) =>
    socketService?.sendUnSubscribe(subscribeMessage);

  return (
    <Row>
        {socketStatus === 'error' && <Alert variant="danger">Something went wrong.</Alert>}
        {socketStatus === 'closed' && <Alert variant="warning">Connection closed.</Alert>}
        {(socketStatus === 'ready' || socketStatus === 'opened') && (
        <>
          <Col md={4}>
            <ConfigForm event={event} sendSubscribe={sendSubscribe} />
          </Col>
          <Col md={8}>
            <Charts charts={state.chart} handleUnSubscribe={handleUnSubscribe} />
          </Col>
        </>
      )}
    </Row>
  );
};

export default Diagrams;
