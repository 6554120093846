import { ChartDataPoint, DiagramConfig } from "src/types";

export const UPDATE_CHART_BY_ID = 'UPDATE_CHART_BY_ID';
export const REQUEST_SUBSCRIPTION = 'REQUEST_SUBSCRIPTION';
export const SUBSCRIBE_CHART = 'SUBSCRIBE_CHART';
export const UNSUBSCRIBE_CHART = 'UNSUBSCRIBE_CHART';
export const INITIALIZE_CHART = 'INITIALIZE_CHART';

interface UpdateChartByIdAction {
  type: typeof UPDATE_CHART_BY_ID;
  payload: {
    subscriptionID: string;
    dataPoints?: ChartDataPoint[];
  };
}

interface RequestSubscriptionAction {
  type: typeof REQUEST_SUBSCRIPTION;
  payload: {
    subscriptionID: string;
    diagramConfig: DiagramConfig;
    displayName: string;
  };
}

interface SubscribeChartAction {
  type: typeof SUBSCRIBE_CHART;
  payload: {
    subscriptionID: string;
  };
}

interface UnSubscribeChartAction {
  type: typeof UNSUBSCRIBE_CHART;
  payload: {
    subscriptionID: string;
  };
}

export const requestSubscription = (subscriptionID: string, diagramConfig: DiagramConfig, displayName: string): RequestSubscriptionAction => ({
  type: REQUEST_SUBSCRIPTION,
  payload: {
    subscriptionID,
    diagramConfig,
    displayName,
  },
});

export const subscribeChart = (subscriptionID: string): SubscribeChartAction => ({
  type: SUBSCRIBE_CHART,
  payload: {
    subscriptionID,
  },
});

export const unSubscribeChart = (subscriptionID: string): UnSubscribeChartAction => ({
  type: UNSUBSCRIBE_CHART,
  payload: {
    subscriptionID,
  },
});

export const updateChartById = (subscriptionID: string, dataPoints?: ChartDataPoint[]): UpdateChartByIdAction => ({
  type: UPDATE_CHART_BY_ID,
  payload: {
    subscriptionID,
    dataPoints,
  },
});

interface InitializeChartAction {
  type: typeof INITIALIZE_CHART;
}

export const initializeChart = (): InitializeChartAction => ({
  type: INITIALIZE_CHART,
});

export type ChartAction = UpdateChartByIdAction | RequestSubscriptionAction | SubscribeChartAction | UnSubscribeChartAction | InitializeChartAction;

