import React, { useEffect, useState } from "react";
import { Button, Card, Form } from 'react-bootstrap';
import {
  METRIC_PASS_TOTAL,
  MOCK_SCOPES,
  Metrics
} from "./mockConfig";
import {ChartSubscription, ChartType, DiagramConfig, Match, Metric, SubscribeAction, Subscription} from "src/types";
import { splitEventIntoTeams } from '../../../../utils/splitEventIntoTeams';
import { generateSubscriptionID } from "src/utils/generateSubscriptionID";
import { useSelector } from "react-redux";
import { State } from "src/redux/types";
import { generateChartDisplayName } from "src/utils/generateChartDisplayName";
import { convertUTCToCET } from 'src/utils/convertUTCToCET';

interface ConfigFormProps {
  sendSubscribe: (subscribeMessage: Subscription, displayName: string) => void;
  event?: Match;
}

const ConfigForm = ({ sendSubscribe, event }: ConfigFormProps) => {
  const state = useSelector((state: State) => state);

  const [selectedChartType, setSelectedChartType] = useState<string>("1");
  const [metricName, setMetricName] = useState<string>(METRIC_PASS_TOTAL);
  const [intervalValue, setIntervalValue] = useState<number>(60);
  const [chartType, setChartType] = useState<ChartType>(ChartType.Simple);
  const [selectedTeam, setSelectedTeam] = useState<string>(MOCK_SCOPES[0]);
  const [alreadySubscribed, setAlreadySubscribed] = useState<boolean>(false);

  const handleSubscribe = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const select_team = formData.get("select_team") as string;
    const interval = parseInt(formData.get("interval") as string);
    const metric_name = formData.get("metric_name") as string;
    const chart_type = parseInt(formData.get("chart_type") as string);

    const scopes = (selectedChartType === "1" || +selectedChartType === ChartType.Cumulative) ? [select_team] : MOCK_SCOPES;

    const subscription: Subscription = {
      action: SubscribeAction.Subscribe,
      diagramConfig: {
        scopes,
        interval,
        metric_name,
        chart_type,
      },
    };

    sendSubscribe(subscription, generateChartDisplayName(teams, scopes, select_team, chart_type, metric_name, interval));
  };

  const checkSubscriptionID = (arr: ChartSubscription[], subscriptionID: string): boolean => {
    return arr.some((item) => item.subscriptionID === subscriptionID);
  }

  useEffect(() => {
    const diagramConfig: DiagramConfig = {
      scopes: (selectedChartType === "1" || +selectedChartType === ChartType.Cumulative) ? [selectedTeam] : MOCK_SCOPES,
      interval: intervalValue,
      metric_name: metricName,
      chart_type: chartType,
    };
    const subscriptionID = generateSubscriptionID(diagramConfig);
    setAlreadySubscribed(checkSubscriptionID(state.chart, subscriptionID))
  }, [selectedChartType, selectedTeam, intervalValue, metricName, chartType, state.chart]);


  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedChartType(event.target.value);
  };

  const teams = splitEventIntoTeams(event?.eventName);

  return (
    <Card className="p-1 p-md-4">
      <Card style={{ padding: '10px', background: '#F8F8F8', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <Card.Title><a target='_blank' href={`${event?.eventUrl}`} rel="noreferrer">Event name: {event?.eventName}</a></Card.Title>
        <Card.Text>Start time: {`${convertUTCToCET(event?.startTime)} (CET)`}</Card.Text>
        <Card.Text>{event?.league}</Card.Text>
        <Card.Text>{event?.provider}</Card.Text>
      </Card>
      <Form onSubmit={handleSubscribe} style={{ marginTop: "3rem" }}>
        <strong>Metric name</strong>
        <Form.Select name="metric_name" value={metricName} onChange={(e) => setMetricName(e.target.value)}>
          {Metrics.map((v: Metric) => (
              <option value={v.value}>
                {v.label}
              </option>
          ))}
        </Form.Select>
        <br />
        <strong>Interval</strong>
        <Form.Select name="interval" value={intervalValue} onChange={(e) => setIntervalValue(parseInt(e.target.value))}>
          <option value={60}>1 minute</option>
          <option value={180}>3 minutes</option>
          <option value={300}>5 minutes</option>
        </Form.Select>
        <br />
        <strong>Chart type</strong>
        <Form.Select name="chart_type" value={chartType} onChange={(e) => {setChartType(parseInt(e.target.value) as ChartType);handleSelectChange(e)}}>
          {Object.keys(ChartType)
            .filter((key) => isNaN(Number(key)))
            .map((key) => (
              <option key={key} value={ChartType[key as keyof typeof ChartType]}>
                {key}
              </option>
            ))}
        </Form.Select>
        <br />
        <strong>Scope</strong>
        {(selectedChartType === "1" || +selectedChartType === ChartType.Cumulative) && (
          <Form.Select name="select_team" value={selectedTeam} onChange={(e) => setSelectedTeam(e.target.value)}>
            {MOCK_SCOPES.map((scope, i) => (
              <option key={scope} value={scope}>
                {teams ? `${Object.values(teams)[i]} (${i === 0 ? 'home team' : 'away team'})` : scope}
              </option>
            ))}
          </Form.Select>
        )}
        <br />
        <Button variant={alreadySubscribed ? "warning" : "primary"} type="submit" disabled={alreadySubscribed}>
          {alreadySubscribed ? 'Subscribed' : 'Subscribe'}
        </Button>
      </Form>
    </Card>
  );
}

export default ConfigForm;
