export function convertUTCToCET(utcTimeString?: string): string {

    if (!utcTimeString) {
      return ''
    }

    const [month, day, time] = utcTimeString.split('. ');

    const currentYear = new Date().getFullYear();
  
    const utcDate = new Date(`${currentYear}-${month}-${day}T${time}:00Z`);
  
    const cetDate = new Date(utcDate.toLocaleString('en-US', { timeZone: 'Europe/Budapest' }));

    const formatter = new Intl.DateTimeFormat('en-US', {
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      });
      const formattedDate = formatter.format(cetDate).replace(/\//g, '. ');

    return formattedDate.replace(',', '.');
}
  