import React from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';
import '../../styles/components/Header.module.scss';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" className="mb-4 navbar">
    <Container>
        <Link to={'/'} style={{ textDecoration: 'none', color: 'white', fontSize: '20px' }}>Soccer Analytics</Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Link to={'/'} style={{ textDecoration: 'none', color: 'white', fontSize: '16px', paddingLeft: '1rem'  }}>Home</Link>
            <Link to={'/matches-today'} style={{ textDecoration: 'none', color: 'white', fontSize: '16px', paddingLeft: '3rem'  }}>Today's matches </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
