import React, { useState, useEffect } from 'react';
import { Card, Modal, Button } from 'react-bootstrap';
import { Match } from 'src/types';
import { URL } from 'src/config/config';
import { convertUTCToCET } from 'src/utils/convertUTCToCET';
import { Link } from 'react-router-dom';
import axios from 'axios';

interface MatchCardProps {
  match: Match;
}

interface Status {
  [key: string]: string;
}

const MatchCard = ({ match }: MatchCardProps) => {

  const [status, setStatus] = useState<Status | null>(null);
  const [showModal, setShowModal] = useState(false);


  useEffect(() => {
      axios
      .get(`${URL}/${match.provider}/${match.eventId}/status`)
      .then((response) => {
        console.log(response.data)
        setStatus(response.data);
      })
      .catch(error => console.error('Error fetching status data:', error));
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  return (
    <Card className="my-2">
      <Card.Body>
        <Card.Title><a target='_blank' href={`${match.eventUrl}`} rel="noreferrer">Event name: {match.eventName}</a></Card.Title>
        <Card.Text>Start time: {`${convertUTCToCET(match.startTime)} (CET)`}</Card.Text>
        <Card.Text>League: {match.league}</Card.Text>
        <Card.Text>Match provider: {match.provider}</Card.Text>
        <a href={`${URL}/${match.provider}/${match.eventId}/stats`}>View Raw Details</a><br/>
        <a href={`${URL}/${match.provider}/${match.eventId}/game`}>View Game Info</a><br/>
        <Link to={`/${match.provider}/${match.eventId}`}>View</Link><br/>
        <Link to={`/${match.provider}/${match.eventId}/diagrams`}>View Diagrams</Link><br/>
          <Modal show={showModal} onHide={handleCloseModal} size='lg'>
            <Modal.Header closeButton>
              <Modal.Title>Match status</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {status ? (
                <ul>
                  {Object.keys(status).reverse().map((key) => (
                    <li key={key}>{status[key]}</li>
                  ))}
                </ul>
              ) : (
                <p>Loading data...</p>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseModal}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        <Button variant="link" style={{ padding: 0 }} onClick={handleShowModal}>
          View Status
        </Button>
      </Card.Body>
    </Card>
  );
};

export default MatchCard;
