import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { fetchMatches } from '../redux/actions/matchActions';
import MatchCard from './common/MatchCard';
import { State } from 'src/redux/types';

const Dashboard = () => {
  const dispatch = useDispatch<any>(); // TODO: define the proper type instead of any
  const matches = useSelector((state: State) => state.match.matches);

  useEffect(() => {
    dispatch(fetchMatches());
  }, [dispatch]);

  return (
    <Container>
      <Row>
        <Col>
          <h2>Ongoing Matches</h2>
        </Col>
      </Row>
      <Row>
        {matches.length > 0 ? 
          matches.map(match => (
            match && <Col key={match.eventId} xs={12} sm={6} md={4} lg={3}>
              <MatchCard match={match} />
            </Col>
          )) : <p>No matches found.</p>
        }
      </Row>
    </Container>
  );
};

export default Dashboard;
