import axios from 'axios';
import { MATCH_API } from 'src/config/config';

export const FETCH_MATCHES_REQUEST = 'FETCH_MATCHES_REQUEST';
export const FETCH_MATCHES_SUCCESS = 'FETCH_MATCHES_SUCCESS';
export const FETCH_MATCHES_FAILURE = 'FETCH_MATCHES_FAILURE';

export const fetchMatchesRequest = () => {
  return {
    type: FETCH_MATCHES_REQUEST,
  };
};

export const fetchMatchesSuccess = (matches: any) => {
  return {
    type: FETCH_MATCHES_SUCCESS,
    payload: matches,
  };
};

export const fetchMatchesFailure = (error: any) => {
  return {
    type: FETCH_MATCHES_FAILURE,
    payload: error,
  };
};

export const fetchMatches = () => {
  return (dispatch: any) => {
    dispatch(fetchMatchesRequest());
    axios
      .get(`${MATCH_API}/matches`)
      .then((response) => {
        const matches = response.data;
        dispatch(fetchMatchesSuccess(matches));
      })
      .catch((error) => {
        dispatch(fetchMatchesFailure(error.message));
      });
  };
};
