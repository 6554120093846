import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { chartConfig } from "./chartConfig";
import ReactEcharts from "echarts-for-react"; 
import { ChartSubscription, DiagramConfig } from "src/types";
import CloseButton from 'react-bootstrap/CloseButton';
import { isMetricGoals } from "src/utils/isMetricGoals";

interface ChartsProps {
    charts: ChartSubscription[];
    handleUnSubscribe: (diagramConfig: DiagramConfig) => void;
}

const Charts = ({ charts, handleUnSubscribe }: ChartsProps) => {

    return (
        <Card className="p-1 p-md-4">
        {charts.length === 0 && 'No subscriptions yet.'}
        {charts?.map((chart) => (
            chart.subscribed && chart.dataPoints.length === 0 ? (
            'Diagram loading...'
            ) : (
            chart.subscribed && chart.dataPoints.some(dataPoint => dataPoint.value !== null) && (
                <Card style={{ marginBottom: '1rem', background: '#F8F8F8' }} key={chart.subscriptionID}>
                    <Row className="p-3">
                        <Col xs={10} className="d-flex"><strong>{chart.displayName}</strong></Col>
                        <Col xs={2} className="d-flex"><CloseButton style={{ marginLeft: 'auto' }} onClick={() => handleUnSubscribe(chart.diagramConfig)} /></Col>
                    </Row>
                    <div style={{ overflowX: 'scroll'}}>
                        <ReactEcharts option={chartConfig(chart)} style={{ width: '800px', height: isMetricGoals(chart)? "150px" : "300px" }} />
                    </div>
                </Card>
            ))
        ))}
        </Card>
    )
}

export default Charts