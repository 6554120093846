import { Teams } from "src/types";

export const splitEventIntoTeams = (matchString?: string): Teams | null => {

  if (!matchString) {
    return null;
  }

  const separator = " v ";
  const index = matchString.indexOf(separator);
  
  if (index === -1 || matchString.length <= index + separator.length) {
    return null;
  }

  const team1 = matchString.substring(0, index).trim();
  const team2 = matchString.substring(index + separator.length).trim();

  return { homeTeam : team1, awayTeam : team2 };
}
  