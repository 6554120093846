export interface Match {
    eventName: string;
    eventUrl: string;
    startTime: string;
    provider: string;
    eventId: string;
    league: string;
}

export interface DiagramConfig {
    scopes: string[];
    interval: number;
    metric_name: string;
    chart_type: ChartType;
}

export interface Subscription {
    action: SubscribeAction.Subscribe | SubscribeAction.UnSubscribe,
    diagramConfig: DiagramConfig,
}

export enum SubscribeAction {
    Subscribe = "subscribe",
    UnSubscribe = "unsubscribe",
    Subscribed = "subscribed",
    UnSubscribed = "unsubscribed"
}

export enum ChartType {
    Dominance = 0,
    Simple = 1,
    Sum = 2,
    Cumulative = 3,
    CumulativeSum = 4,
}

export const chartTypeString: string[] = ["dominance", "simple_linechart", "sum_chart", "cumulative_linechart",
"cumulative_sum"]

export enum PeriodID {
    FirstHalf = 2,
    SecondHalf = 4,
    FirstHalfExtra = 1,
    SecondHalfExtra = 3,
}

export interface ChartDataPoint {
    timestamp: number;
    label: string;
    value: number | null;
    periodID: PeriodID | null;
}

export interface ChartSubscription {
    subscriptionID: string,
    subscribed: boolean,
    diagramConfig: DiagramConfig,
    displayName: string,
    dataPoints: ChartDataPoint[],
}

export interface Teams {
    homeTeam: string;
    awayTeam: string;
}

export type WebSocketStatus = "ready" | "opened" | "closed" | "error";

export type Metric = { value: string; label: string; }

export type FilteredMatchFields = {
    eventId: string;
    streamProviderName: string;
    eventName: string;
    startDate: string;
    eventUrl: string;
};
